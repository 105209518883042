import React from 'react';
import {
    BrowserRouter,
    Route,
    Routes,
} from 'react-router-dom';

import './App.css';

const Form37 = React.lazy(() => import('./Forms/form37'));
const Form38 = React.lazy(() => import('./Forms/form38'));
const Form45 = React.lazy(() => import('./Forms/form45'));
const Form46 = React.lazy(() => import('./Forms/form46'));

function App() {

    // Test
    return (
        <BrowserRouter>
            <Routes>
                <Route path="37" element={<Form37 />} />
                <Route path="laredoute_439_39_69/38/:guid?" element={<Form38 />} />
                <Route path="stockmann_request_claim/45/:guid?" element={<Form45 />} />
                <Route path="stockmann_request_recall/46/:guid?" element={<Form46 />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
